import { PortfolioProvider } from '../context/context';
import BlogHero from '../components/BlogHero/BlogHero';
import React from 'react';
import Fade from 'react-reveal/Fade';

import { Link, graphql, navigate } from 'gatsby'
import PreviewCompatibleImage from '/src/components/PreviewCompatibleImage'
import {CardColumns,  Card, Button } from 'react-bootstrap';


class TagRoute extends React.Component {
    render() {
      
        const posts = this.props.data.allMarkdownRemark.edges
        const postLinks = posts.map((post) => (
            <li key={post.node.fields.slug}>
                <Link to={post.node.fields.slug}>
                    <h2 className="is-size-2">{post.node.frontmatter.title}</h2>
                </Link>
            </li>
        ))
        const tag = this.props.pageContext.tag
        const totalCount = this.props.data.allMarkdownRemark.totalCount
        const tagHeader = `${totalCount} post${totalCount === 1 ? '' : 's'
            } tagged with �${tag}�`


        return (
           

            <PortfolioProvider value={{
                hero: {
                    sectionId: 'tagHero',
                    title: tag,
                    helmet: tag,
                    subtitle: "",
                    cta: '',
                    message: '',
                    backButton: true,
                    navigation: [
                        {
                            title: 'Home',
                            url: '/',
                            local: false,

                        },
                        {
                            title: 'Premium',
                            url: '/premium',
                            local: false,

                        },
                        {
                            title: 'Blogs',
                            url: '/blog',
                            local: false,

                        },
                       
                    ]}}}>
            <BlogHero />
               
                <section id="blog">
                    <Fade bottom duration={1000} delay={300} distance="0px">

                    <CardColumns className="blog-deck" >
                        {posts &&
                            posts.map(({ node: post }) => (
                                <Link to={post.fields.slug} className="link">
                                    <Card className="blog-cards" bg="light">

                                        <PreviewCompatibleImage
                                            imageInfo={{
                                                image: post.frontmatter.featuredimage,
                                                alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                                            }}
                                        />
                                        <Card.Body>
                                            <Card.Title className="blog-cards__title" >{post.frontmatter.title}</Card.Title>
                                            <Card.Subtitle className="blog-cards__date mb-2 text-muted">{post.frontmatter.date}</Card.Subtitle>

                                            <Card.Text className="blog-cards__text">{post.frontmatter.description}</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            ))}
                    </CardColumns>
                        </Fade>
                </section>
    </PortfolioProvider>


               
        )
    }
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            featuredpost
            description
            featuredimage {
                childImageSharp {
                fluid(maxWidth: 120, quality: 100) {
                    ...GatsbyImageSharpFluid   

                }
              }
            }
          }
        }
      }
    }
  }
`