import React, { useContext, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { Link as Scroll } from 'react-scroll';
import { Helmet } from 'react-helmet'
import { Link, graphql, useStaticQuery } from 'gatsby';
import PortfolioContext from '../../context/context';


import BackgroundImage from 'gatsby-background-image'







const BlogHero = () => {
    const { hero } = useContext(PortfolioContext);
    const { sectionId, title, name, subtitle, navigation, message, helmet, image, classname } = hero;
    const [isDesktop, setIsDesktop] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (window.innerWidth > 769) {
            setIsDesktop(true);
            setIsMobile(false);
        } else {
            setIsMobile(true);
            setIsDesktop(false);
        }
    }, []);


    return (






        <section id={sectionId} className="jumbotron">

            <Helmet title={`365 Nutrition | ${helmet}`} />

            <Container>

                <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
                    <div className="hero-header">
                        {navigation && navigation.map((menu) => {


                            const { title, url, local } = menu;
                            return (
                                //               <div>
                                //              <span>
                                //                     {backButton ? <Link className="cta-btn cta-btn--hero" href={url} onClick={() => this.props.history.goBack}>Back</Link> : ''}
                                //             </span>
                                <span >


                                    { local ? <Scroll className="cta-btn cta-btn--hero" to={url} smooth duration={1000}>
                                        {title}
                                    </Scroll> : <Link className="cta-btn cta-btn--hero" href={url}  >
                                            {title}
                                        </Link>}

                                </span>
                                //          </div>
                            );
                        })}
                    </div>

                </Fade>

                <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">

                    <h1 className="hero-title">
                        {title}{' '}
                        <span className="text-color-main">{name || ''}</span>
                        <br />
                        <p> {subtitle}</p>
                    </h1>
                </Fade>
                <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
                    <div className="badges">


                        <p>{message}</p>

                    </div>
                </Fade>

            </Container>
        </section>

    )
}
    


export default BlogHero;